<template>
    <page-content>

        <common-table
                ref="table"
                path="web/beian/cashout"
                :columns="columns">

            <template slot="search">
                <!--        <a-button  ghost type="primary" @click="add">新增</a-button>-->
            </template>

            <template slot="logo" slot-scope="{record}">
                <a-avatar shape="square" :size="60" :src="record.logo">{{record.title}}</a-avatar>
            </template>

            <template slot="status" slot-scope="{record}">

                <a-tag v-if="record.status==1" color="green">已发放</a-tag>
                <a-dropdown :trigger="['click']" v-else>
                    <a-menu slot="overlay">
                        <a-menu-item key="2" @click="handleSetStatus(record,1)">已发放</a-menu-item>
                    </a-menu>
                    <a-button type="danger" size="small">待处理
                        <a-icon type="down"></a-icon>
                    </a-button>
                </a-dropdown>

            </template>


        </common-table>

    </page-content>
</template>
<script>
    export default {
        components: {},
        data() {
            return {}
        },

        computed: {
            columns() {
                return [
                    {
                        title: '用户',
                        dataIndex: 'nickName',
                    },
                    {
                        title: '手机号',
                        dataIndex: 'phone'
                    },
                    {
                        title: '提现积分',
                        dataIndex: 'points',
                    },
                    {
                        title: '户名',
                        dataIndex: 'accountName',
                    },
                    {
                        title: '开户行',
                        dataIndex: 'accountBank',
                    },
                    {
                        title: '账号',
                        dataIndex: 'accountNumber',
                    },
                    {
                        title: '申请时间',
                        dataIndex: 'createdAt'
                    },
                    {
                        title: '状态',
                        dataIndex: 'status',
                        scopedSlots: {customRender: 'status'},
                        filters: [
                            {text: "全部", value: ""},
                            {text: "待处理", value: 0},
                            {text: "已发放", value: 1},
                        ],
                        filterMultiple: false,
                    }
                ]
            }
        },
        methods: {

            handleSetStatus(record, status) {
                this.$put("web/beian/cashout/status", {id: record.id, status}).then(suc => {
                    this.getList()
                })
            },
            getList() {
                this.$refs.table.getData()
            }

        }
    }
</script>
<style lang="less" scoped>

</style>
